import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

import {
CheckIcon,
UserIcon,
LockClosedIcon ,
} from '@heroicons/react/outline'
import {
Switch,
Route,
withRouter , Redirect
} from "react-router-dom";
import {Helmet} from "react-helmet";
import { observable, makeObservable, } from 'mobx'

import { observer, inject } from 'mobx-react'
@inject('store')
@observer
class Login extends Component {

	
	@observable email = ""
	@observable password = ""
	@observable fname = ""
	@observable lname = ""
	@observable errorMessage = ""

	constructor(){
		super()
		makeObservable(this)
	}

	onChange = (val) => {
		this.currentPromptOption = val
		console.log(this.currentPromptOption)
	}

	onChangeAny = (val, attr) => {
		this[attr] = val
		this.errorMessage = ""
	}

	onLogin = async (e) => {
		try {
			e.preventDefault()
			let data = await this.props.store.api.post('/auth/signin', {
				email: this.email,
				password: this.password,
			}).then(({data}) => data)
			this.props.store.loginWithDataTokenAndProfile(data)
		} catch (err){
			console.log(err)
			console.log(err?.response?.data?.message)
			if(err?.response?.data?.message){
				this.errorMessage = err?.response?.data?.message
			}
		}
	}	

	
	

	onSignup = async (e) => {
		try {
			e.preventDefault()
			this.errorMessage = ""
			console.log('signup')
			let data = await this.props.store.api.post('/auth/signup', {
						email: this.email,
						password: this.password,
						fname: this.fname,
						lname: this.lname,
						referral: this.props.store.referral
					}).then(({data}) => data)
					console.log(`onSignup`,data)
			if(data.token && data.profile){
				this.props.store.loginWithDataTokenAndProfile(data)
			}
		} catch (err){
			console.log(err)
			console.log(err?.response?.data?.message)
			if(err?.response?.data?.message){
				this.errorMessage = err?.response?.data?.message
			}
		}
	}

	// Currently Selected Input Option

	render() {
	return (
		<>
			<Helmet>
				<title>{`Beta Login - WRLD.AI`}</title>
			</Helmet>
			<div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">

				<div className="text-center mb-6">
					<Logo />
					<div className="text-3xl md:text-5xl relative font-medium text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-600 mb-4">WRLD.AI<span className="font-normal "> Beta Toolset</span>
						<div className="absolute top-0 ml-3 left-full bg-gradient-to-r from-purple-500 to-blue-500 text-white text-sm px-2 py-0.5 hidden md:inline-block rounded-md font-normal ">gpt3+</div>
					</div>
				</div>
			<div className={`min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2`}>
			<div className="align-bottom flex  transform transition-all sm:align-middle transition flex divide-x divide-gray-300 ">
				<NavLink to="/login"  className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-md flex text-${this.props.location.pathname === "/login"  ? "gray-800" : "gray-600"} font-medium  bg-${this.props.location.pathname === "/login" ? "white" : "gray-300"} hover:bg-${this.props.location.pathname === "/login"  ? "white" : "gray-100"} cursor-pointer`} >
						  <div className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${this.props.location.pathname === "/login" ? "green-300" : "gray-200"} text-${this.props.location.pathname === "/login" ? "green" : "gray"}`}>
							  <CheckIcon className={`transition h-4 w-4 text-${this.props.location.pathname === "/login" ? "green-600" : "gray-400"}`} aria-hidden="true" />
						  </div>
						 Login
				  </NavLink>
				  <NavLink to="/signup"  className={`flex-1 justify-center transition py-4 px-4 pr-8 rounded-t-md flex text-${this.props.location.pathname === "/signup"  ? "gray-800" : "gray-600"} font-medium  bg-${this.props.location.pathname === "/signup" ? "white" : "gray-300"} hover:bg-${this.props.location.pathname === "/signup"  ? "white" : "gray-100"} cursor-pointer`} >
						  <div className={`transition mr-4  flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${this.props.location.pathname === "/signup" ? "green-300" : "gray-200"} text-${this.props.location.pathname === "/signup" ? "green" : "gray"}`}>
							  <CheckIcon className={`transition h-4 w-4 text-${this.props.location.pathname === "/signup" ? "green-600" : "gray-400"}`} aria-hidden="true" />
						  </div>
						 Signup
				  </NavLink>
			  </div>
              <div className="px-4 py-4 md:px-12 md:py-12">
				{/* Sorru */}
			  <Switch>
                  <Route path="/login" >
				  	<Logon 
					  landingPageUrl={this.props.store.landingPageUrl}
					  	email={this.email} 
						  password={this.password} 
						  signUp={this.signUpWithGoogle}
						  onChange={this.onChangeAny} 
					onLogin={this.onLogin}		 />
					</Route>
                  <Route path="/signup"  >
				  <Signup 
						email={this.email} 
						password={this.password} 
						fname={this.fname} 
						lname={this.lname} 
						onChange={this.onChangeAny}  
						onSignup={this.onSignup}
					/>
				  </Route>
				  <Route >
				  	<Redirect to="/login" />
					  </Route>
				</Switch>
				{this.errorMessage ? <div className="text-red-600 bg-red-50 rounded-md p-1 text-center mt-4">
				{this.errorMessage}
				</div> : null}
				</div>
				<a href={`https://wrld.tech/`} className="block text-center bg-gray-100 text-gray-500 text-sm p-3 rounded-b-lg hover:bg-gray-200 cursor-pointer">
					A New Tool by the WRLD Tech Team - visit WRLD.Tech to Learn More (we are looking for contributors and beta testers!)
				</a>
			</div>
		</div>
		</>)
		}
  }

  const Logon = observer(({ active, email, password, onChange, onLogin, landingPageUrl, signUp }) => {
	return (
	  <>
<form onSubmit={onLogin}>
              		<div className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${(email && password) ? "green" : "gray"}-300  ${(email && password) ? "bg-green-300" : "bg-gray-300"} `}>
			  		<LockClosedIcon className={`h-8 w-8 ${active ? "text-green-700" : "text-gray-500"} text-${(email && password) ? "green-700" : "gray-500"}`} aria-hidden="true" />
                </div>
               	 <div className="mt-3 text-center ">
                    <div className="text-3xl font-medium text-gray-900">
                    	Log in
                    </div>
					<p className="text-lg text-gray-500">
					Login to your account
					</p>
					<div className="flex flex-col flex-1">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Email Address</label>
						<input value={email} onChange={e=>onChange(e.target.value,'email')} focus="true" type="email" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
					</div>
					<div className="flex flex-col flex-1">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
						<input value={password} onChange={e=>onChange(e.target.value,'password')} type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block" placeholder="*******" />
					</div>
					<div className="flex flex-col">
						<button type="submit" className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
							Log in
						</button>
						{/* <div onClick={signUp} className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
						signUp Google
						</div>
						 */}
						<a href={`https://wrld.tech/contact`} className="mt-4 text-gray-400 text-sm">Forgot your password?</a>
					</div>
                    </div>
                  </form>
	  </>
	)
  })

  const Signup = observer(({ active, email, password, fname, lname, onChange, onSignup }) => {
	return (
	  <>
	  {/* onSignup */}
<form onSubmit={onSignup}>
              		<div className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${email && password ? "green" : "gray"}-300  ${email && password ? "bg-green-300" : "bg-gray-300"} `}>
			  		<UserIcon className={`h-8 w-8 ${active ? "text-green-700" : "text-gray-500"} text-${email && password ? "green-700" : "gray-500"}`} aria-hidden="true" />
                </div>
               	 <div className="mt-3 text-center ">
                    <div className="text-3xl font-medium text-gray-900">
                    	Sign Up
                    </div>
					<p className="text-lg text-gray-500">
					Create your account
					</p>
					<div className="md:flex">
						<div className="flex flex-col min-w-0 md:pr-2 flex-1">
							<label className="text-gray-400 text-sm block mt-4 inline-block text-left">First Name</label>
							<input value={fname} onChange={e=>onChange(e.target.value,'fname')} type="text" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" placeholder="John" />
						</div>
						<div className="flex flex-col min-w-0 md:pl-2 flex-1">
							<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Last Name</label>
							<input value={lname} onChange={e=>onChange(e.target.value,'lname')} type="text" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" placeholder="Smith" />
						</div>
					</div>
					<div className="flex flex-col">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Email Address</label>
						<input value={email} onChange={e=>onChange(e.target.value,'email')} focus="true" type="email" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
					</div>
					<div className="flex flex-col">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
						<input value={password} onChange={e=>onChange(e.target.value,'password')} type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block" placeholder="*******" />
					</div>
					
					<div className="flex flex-col">
						<button type="submit"  className="hover:bg-green-600 bg-green-500 font-medium rounded-lg text-lg px-4 py-2 bg-gray-200 text-white mt-4 border border-gray-300 inline-block" >
							Sign Up
						</button>
					</div>
                    </div>
                  </form>
	  </>
	)
  })


  const Logo = () => (
	<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="black"
	className="w-20 h-20 inline-block"
	viewBox="0 0 1000 1000"
  >
	
  <polygon points="513.09 742.82 531.74 734.6 500 500 595.35 723.7 516.15 799.59 513.09 742.82"/>
  <polygon points="595.35 723.7 610.07 709.6 500 500 666.11 677.6 617.64 776 595.35 723.7"/>
  <polygon points="666.11 677.6 675.12 659.31 500 500 716.84 610.07 704.95 719.12 666.11 677.6"/>
  <polygon points="716.84 610.07 719.05 589.81 500 500 741.41 529.27 767.53 635.81 716.84 610.07"/>
  <polygon points="741.41 529.27 736.55 509.48 500 500 736.86 444.94 797.85 536.12 741.41 529.27"/>
  <polygon points="736.86 444.94 725.53 428 500 500 703.75 367.25 792.24 432.07 736.86 444.94"/>
  <polygon points="703.75 367.25 687.3 355.21 500 500 646.06 305.57 751.38 336.22 703.75 367.25"/>
  <polygon points="646.06 305.57 626.48 299.88 500 500 570.75 267.34 680.2 260.12 646.06 305.57"/>
  <polygon points="570.75 267.34 550.41 268.69 500 500 486.91 257.18 587.29 212.95 570.75 267.34"/>
  <polygon points="486.91 257.18 468.26 265.4 500 500 404.65 276.3 483.85 200.41 486.91 257.18"/>
  <polygon points="404.65 276.3 389.93 290.4 500 500 333.89 322.4 382.36 224 404.65 276.3"/>
  <polygon points="333.89 322.4 324.88 340.69 500 500 283.17 389.92 295.05 280.88 333.89 322.4"/>
  <polygon points="283.17 389.92 280.96 410.19 500 500 258.6 470.72 232.47 364.19 283.17 389.92"/>
  <polygon points="258.6 470.72 263.45 490.52 500 500 263.14 555.06 202.15 463.88 258.6 470.72"/>
  <polygon points="263.14 555.06 274.47 572 500 500 296.26 632.75 207.76 567.93 263.14 555.06"/>
  <polygon points="296.26 632.75 312.7 644.79 500 500 353.95 694.43 248.62 663.78 296.26 632.75"/>
  <polygon points="353.95 694.43 373.52 700.12 500 500 429.25 732.65 319.8 739.88 353.95 694.43"/>
  <polygon points="429.25 732.65 449.59 731.31 500 500 513.09 742.82 412.71 787.05 429.25 732.65"/>
  <path d="m524.15,947.99l1.34,24.88c.22,4.15.45,8.29.67,12.44.06,1.04.11,2.07.17,3.11,0,.11-.1.05-.16.06h-.22c-.15.02-.29.03-.44.04-.29.02-.59.03-.88.04-.59.03-1.17.06-1.76.09-2.35.11-4.7.2-7.05.28-17.04.55-33.98.22-50.99-1.01-34.24-2.47-67.32-8.36-100.31-17.87-1.15-.33-2.29-.66-3.44-1-.57-.17-1.14-.34-1.71-.51-.29-.09-.57-.17-.86-.26-.14-.04-.29-.09-.43-.13-.07-.02-.14-.04-.21-.06-.04-.01-.21,0-.18-.08.6-1.99,1.21-3.98,1.81-5.96,1.21-3.98,2.42-7.95,3.63-11.93,1.21-3.98,2.42-7.95,3.63-11.93.6-1.99,1.21-3.98,1.81-5.96.15-.5.3-.99.45-1.49.08-.25.15-.5.23-.75.04-.12.08-.25.11-.37l.06-.19s.02-.06.03-.09c0-.03-.02-.1.04-.08,1.1.33,2.19.66,3.29.99,16.37,4.84,32.89,8.72,49.71,11.67,33.55,5.88,67.66,7.93,101.66,6.1Z"/>
  <path d="m642.32,31.99c-2.43,7.98-4.86,15.97-7.28,23.95-1.21,3.99-2.43,7.98-3.64,11.98-.15.5-.3,1-.45,1.5-.08.25-.15.5-.23.75-.04.12-.08.25-.11.37-.02.06-.04.12-.06.19-.03.11-.11.01-.17,0-1.1-.33-2.2-.66-3.31-.99-2.21-.65-4.42-1.29-6.64-1.9-16.44-4.58-33-8.19-49.85-10.88-16.85-2.68-33.72-4.4-50.76-5.15-8.63-.38-17.26-.51-25.9-.4-4.3.06-8.59.18-12.89.36-1.07.05-2.14.09-3.21.15-.54.03-1.07.05-1.61.08-.07,0-.13,0-.2.01-.06,0-.15.06-.15-.04,0-.12-.01-.25-.02-.37-.01-.25-.03-.49-.04-.74-.11-1.97-.21-3.94-.32-5.91-.44-8.15-.88-16.3-1.32-24.45-.11-2.04-.22-4.07-.33-6.11-.03-.51-.05-1.02-.08-1.53-.01-.25-.03-.51-.04-.76,0-.13-.01-.25-.02-.38,0-.04-.07-.17,0-.18.07,0,.14,0,.22-.01,1.15-.06,2.3-.12,3.46-.17,4.62-.21,9.24-.36,13.87-.45,17.06-.31,34.02.28,51.03,1.75,34.21,2.97,67.21,9.34,100.07,19.33Z"/>
  <path d="m757.29,157.5c-4.55,6.05-9.1,12.11-13.64,18.16-2.27,3.03-4.55,6.05-6.82,9.08-1.14,1.51-2.27,3.03-3.41,4.54-.14.19-.28.38-.43.57-.08.1-.23-.1-.29-.14-.1-.08-.2-.15-.3-.23-.4-.3-.81-.6-1.21-.9-.81-.6-1.62-1.2-2.43-1.79-12.35-9.02-25.12-17.24-38.45-24.74-13.33-7.5-26.97-14.16-41.09-20.05-7.15-2.98-14.37-5.74-21.69-8.29-3.67-1.28-7.35-2.5-11.06-3.66-.93-.29-1.85-.58-2.78-.87-.23-.07-.46-.14-.7-.21-.03,0-.06-.02-.09-.03-.02,0-.07,0-.06-.04.02-.05.03-.1.05-.16.03-.1.06-.21.1-.31.13-.42.25-.84.38-1.26.51-1.67,1.02-3.35,1.53-5.02,2.14-7.02,4.27-14.05,6.41-21.07,1.07-3.51,2.14-7.02,3.2-10.53l.1-.33s-.02-.09.03-.08c.03,0,.06.02.1.03.06.02.13.04.19.06.26.08.51.16.77.23.51.16,1.02.31,1.53.47,1.02.32,2.04.64,3.05.96,2.03.65,4.06,1.31,6.08,1.99,14.49,4.86,28.61,10.46,42.5,16.84,27.97,12.85,53.83,28.26,78.44,46.75Z"/>
  <path d="m807.98,299.34c-11.3,7.36-22.59,14.72-33.88,22.08-.12.08-.15-.06-.2-.14-.06-.09-.11-.17-.17-.26-.11-.17-.23-.34-.34-.52-.23-.34-.45-.69-.68-1.03-.46-.69-.92-1.38-1.38-2.06-.93-1.38-1.86-2.75-2.81-4.11-1.91-2.75-3.87-5.47-5.86-8.16-4.07-5.48-8.29-10.82-12.69-16.03-8.72-10.33-17.99-20.04-27.92-29.22-5-4.63-10.13-9.08-15.41-13.39-2.67-2.18-5.37-4.31-8.11-6.4-.69-.53-1.38-1.05-2.07-1.57-.09-.07.09-.19.13-.23.06-.08.12-.16.18-.24.12-.16.24-.32.36-.47.24-.32.47-.63.71-.95.95-1.26,1.9-2.53,2.85-3.79,3.97-5.28,7.94-10.57,11.91-15.85,1.98-2.64,3.97-5.28,5.95-7.93.5-.66.99-1.32,1.49-1.98.12-.16.25-.33.37-.5.06-.08.12-.16.19-.25.03-.04.06-.08.09-.12.02-.02.03-.04.05-.06.02-.02,0-.09.05-.06.37.28.75.56,1.12.84,1.49,1.12,2.96,2.26,4.43,3.41,10.65,8.32,20.76,17.16,30.43,26.6,19.49,19.02,36.36,39.57,51.22,62.38Z"/>
  <path d="m976.46,389.25c-8.21,1.91-16.41,3.82-24.62,5.72-4.1.95-8.21,1.91-12.31,2.86l-1.54.36c-.26.06-.51.12-.77.18-.06.02-.13.03-.19.04-.1.02-.07-.09-.08-.15-.03-.13-.06-.27-.1-.41-.25-1.08-.51-2.16-.77-3.23-.52-2.15-1.05-4.29-1.6-6.43-4.25-16.52-9.4-32.67-15.5-48.61-6.1-15.94-13.06-31.4-20.93-46.55-3.94-7.57-8.08-15.02-12.44-22.35-2.14-3.61-4.34-7.18-6.58-10.73-.56-.88-1.12-1.76-1.68-2.63-.28-.44-.56-.87-.85-1.31-.14-.22-.28-.44-.42-.65-.04-.06-.25-.24-.13-.31.12-.07.23-.15.35-.22,1.84-1.2,3.69-2.4,5.53-3.6,7.16-4.66,14.31-9.33,21.47-13.99,1.79-1.16,3.58-2.33,5.37-3.5.22-.15.45-.29.67-.44.11-.07.22-.15.34-.22.06-.04.11-.07.17-.11.06-.04.09.1.11.13.29.44.57.88.86,1.32.57.88,1.14,1.77,1.7,2.65,2.27,3.57,4.5,7.17,6.68,10.79,8.79,14.61,16.76,29.58,23.98,45.03,14.54,31.12,25.52,62.89,33.3,96.34Z"/>
  <path d="m925.26,551.57c-7.48-.91-14.97-1.81-22.45-2.72-3.74-.45-7.48-.91-11.22-1.36-1.87-.23-3.74-.45-5.61-.68-.23-.03-.47-.06-.7-.08-.06,0-.12-.01-.18-.02-.08,0-.06-.07-.06-.13.02-.13.03-.26.05-.39.06-.52.12-1.04.18-1.56.12-1.04.24-2.08.35-3.12,1.64-15.21,2.37-30.37,2.2-45.67-.16-15.29-1.22-30.44-3.18-45.61-.99-7.68-2.22-15.31-3.67-22.92-.72-3.77-1.49-7.52-2.32-11.27-.21-.93-.42-1.86-.63-2.79-.05-.23-.11-.47-.16-.7-.03-.12-.05-.23-.08-.35-.01-.05.05-.02.07-.02.03,0,.06-.01.08-.02.05-.01.11-.02.16-.04.44-.1.88-.2,1.31-.31,1.75-.41,3.5-.81,5.25-1.22,7.22-1.68,14.44-3.36,21.66-5.03,3.61-.84,7.22-1.68,10.83-2.52.06-.01.1-.02.12.05.01.06.03.12.04.19.03.12.06.25.09.38.06.25.12.5.17.75.12.5.23,1,.34,1.51.23,1.01.45,2.01.67,3.02.44,2.02.86,4.04,1.27,6.07,3.03,14.99,5.25,30.03,6.67,45.26,2.86,30.65,2.44,60.75-1.27,91.31Z"/>
  <path d="m827.76,666.39c-12.02-6.1-24.04-12.2-36.06-18.31-.13-.06-.02-.15.02-.23.05-.09.09-.19.14-.28.09-.19.19-.37.28-.56.19-.37.38-.75.56-1.12.37-.75.75-1.5,1.11-2.25.74-1.51,1.46-3.02,2.18-4.54,1.43-3.05,2.82-6.11,4.15-9.2,2.69-6.21,5.17-12.49,7.47-18.85,4.59-12.72,8.36-25.59,11.34-38.78,1.53-6.76,2.84-13.55,3.94-20.39.55-3.42,1.04-6.84,1.48-10.27.06-.43.11-.86.16-1.29l.08-.64c0-.05.01-.11.02-.16,0-.03,0-.05,0-.08,0-.02-.02-.07.02-.06l.28.03,2.24.27c1.49.18,2.99.36,4.48.54,6.51.79,13.02,1.58,19.53,2.37,3.26.39,6.51.79,9.77,1.18.81.1,1.63.2,2.44.3.41.05.81.1,1.22.15.09.01.26-.06.24.1-.01.12-.03.24-.04.36-.03.24-.06.48-.09.72-.24,1.93-.49,3.85-.76,5.77-1.88,13.38-4.48,26.56-7.82,39.66-6.73,26.39-16.09,51.27-28.41,75.55Z"/>
  <path d="m834.15,857.25c-5.8-6.2-11.6-12.4-17.4-18.6-2.9-3.1-5.8-6.2-8.7-9.3-.36-.39-.73-.77-1.09-1.16-.09-.1-.18-.19-.27-.29-.05-.05-.28-.19-.17-.29.19-.18.39-.37.58-.55.78-.73,1.56-1.47,2.33-2.2,1.55-1.48,3.09-2.97,4.62-4.47,12.19-11.95,23.6-24.49,34.36-37.74,10.75-13.25,20.66-27,29.84-41.39,4.61-7.23,9.01-14.59,13.21-22.07,2.11-3.77,4.16-7.56,6.16-11.39.5-.96,1-1.92,1.49-2.88.25-.48.49-.96.74-1.45.06-.12.12-.24.18-.36.05-.09.1.01.15.04.06.03.11.06.17.08.22.11.44.23.67.34,1.78.9,3.56,1.81,5.34,2.71,7.29,3.7,14.58,7.4,21.87,11.1,1.82.92,3.65,1.85,5.47,2.78.46.23.91.46,1.37.69.23.12.46.23.68.35.11.06.23.12.34.17.03.01.06.03.08.04.06.03-.01.06-.03.09-.03.06-.06.13-.1.19-.52,1.03-1.05,2.06-1.58,3.08-2.12,4.09-4.29,8.15-6.52,12.19-8.26,14.92-17.24,29.31-27.02,43.29-19.68,28.15-41.7,53.54-66.78,76.99Z"/>
  <path d="m667.97,894.07c-2.99-7.01-5.97-14.01-8.96-21.02-1.49-3.5-2.99-7.01-4.48-10.51-.75-1.75-1.49-3.5-2.24-5.26-.05-.11-.09-.22-.14-.33-.02-.06-.05-.11-.07-.16-.02-.05.05-.03.08-.04.03-.01.06-.02.08-.04.22-.09.44-.19.66-.28.44-.19.88-.38,1.33-.57.88-.38,1.76-.77,2.64-1.16,13.98-6.19,27.49-13.13,40.65-20.92,13.16-7.79,25.75-16.28,37.9-25.56,6.02-4.6,11.89-9.36,17.63-14.3,2.91-2.51,5.78-5.05,8.62-7.65.71-.65,1.42-1.31,2.13-1.96.35-.33.71-.66,1.06-.99.02-.02.04-.04.07-.06.04-.04.04.03.06.05.04.04.08.09.12.13.08.09.16.17.24.26.16.17.33.35.49.52,1.31,1.4,2.61,2.8,3.92,4.19,5.14,5.49,10.28,10.99,15.42,16.48,1.28,1.37,2.57,2.75,3.85,4.12.64.69,1.28,1.37,1.93,2.06.32.34.64.69.96,1.03.16.17.32.34.48.51.05.05.27.17.17.26-.09.08-.18.17-.27.26-2.9,2.71-5.84,5.37-8.81,8-11.45,10.11-23.36,19.54-35.82,28.37-25.12,17.8-51.39,32.48-79.71,44.55Z"/>
  <path d="m519.79,867.05c-.73-13.46-1.45-26.92-2.18-40.38,0-.15.12-.1.21-.1.11,0,.21-.01.32-.02.21-.01.42-.02.64-.04.42-.02.85-.05,1.27-.08.85-.05,1.69-.11,2.54-.17,1.69-.12,3.38-.25,5.07-.39,3.37-.29,6.73-.62,10.09-1.01,6.67-.77,13.3-1.75,19.91-2.94,13.31-2.38,26.35-5.56,39.27-9.57,6.55-2.03,13.02-4.26,19.42-6.7,3.16-1.2,6.3-2.45,9.42-3.75.77-.32,1.55-.65,2.32-.98.02-.01.05-.02.07-.03.02,0,.05-.07.07-.02.02.05.04.09.06.14.04.09.08.18.12.28.08.18.16.37.24.55l.47,1.11c.63,1.48,1.26,2.95,1.89,4.43,2.61,6.12,5.22,12.24,7.82,18.36,1.3,3.06,2.61,6.12,3.91,9.18.33.76.65,1.53.98,2.29.08.19.16.38.24.57.04.1.08.19.12.29.06.15-.1.14-.18.18-.41.18-.83.35-1.24.52-1.66.7-3.32,1.38-4.99,2.06-12.53,5.06-25.24,9.4-38.25,13.06-26.22,7.37-52.45,11.7-79.64,13.17Z"/>
  <path d="m387.16,871.06c-2.18,7.18-4.37,14.36-6.55,21.54-1.09,3.59-2.18,7.18-3.27,10.77-.55,1.79-1.09,3.59-1.64,5.38-.07.22-.14.45-.2.67-.03.11-.07.22-.1.34,0,.03.02.09-.03.08-.03,0-.07-.02-.1-.03-.06-.02-.13-.04-.2-.06-.52-.16-1.04-.32-1.56-.48-1.04-.32-2.08-.65-3.12-.98-14.57-4.62-28.79-9.99-42.78-16.14-28.18-12.39-54.29-27.37-79.21-45.45-1.69-1.23-3.37-2.46-5.04-3.72-.21-.16-.42-.31-.63-.47-.08-.06.04-.1.07-.14l.11-.14c.07-.09.14-.19.21-.28.28-.38.56-.75.85-1.13.56-.75,1.13-1.5,1.69-2.25,2.25-3,4.51-6,6.76-9,4.51-6,9.02-12,13.52-18,.27-.36.53-.71.8-1.07.07-.09.13-.18.2-.27.03-.04.07-.09.1-.13.03-.04.05.01.07.03.02.02.05.04.07.05.19.14.38.28.57.43.76.57,1.52,1.13,2.28,1.69,1.53,1.13,3.06,2.24,4.61,3.34,3.1,2.22,6.24,4.38,9.4,6.51,6.39,4.28,12.89,8.37,19.53,12.26,26.33,15.46,54.39,27.77,83.6,36.65Z"/>
  <path d="m192.02,700.66c11.29-7.36,22.59-14.72,33.88-22.08.12-.08.15.06.19.14.06.09.11.17.17.26.11.17.23.34.34.52l.68,1.03c.46.69.92,1.38,1.38,2.06.93,1.38,1.86,2.75,2.81,4.11,1.91,2.75,3.87,5.47,5.86,8.16,4.07,5.48,8.29,10.82,12.69,16.03,8.72,10.33,17.99,20.04,27.91,29.22,5,4.63,10.14,9.08,15.41,13.39,2.67,2.18,5.37,4.31,8.11,6.4.69.52,1.38,1.05,2.07,1.57.09.07-.09.19-.13.23-.06.08-.12.16-.18.24-.12.16-.24.32-.36.47-.24.32-.47.63-.71.95-.95,1.26-1.9,2.53-2.85,3.79-3.97,5.28-7.94,10.57-11.91,15.85-1.98,2.64-3.97,5.28-5.95,7.93-.5.66-.99,1.32-1.49,1.98-.12.16-.25.33-.37.5-.06.08-.12.16-.19.25-.03.04-.06.08-.09.12-.02.02-.03.04-.05.06-.02.02,0,.09-.05.06-.37-.28-.75-.56-1.12-.84-1.49-1.12-2.96-2.26-4.43-3.41-10.65-8.32-20.76-17.16-30.43-26.6-19.49-19.02-36.36-39.57-51.22-62.38Z"/>
  <path d="m124.11,744.91c-7.15,4.66-14.3,9.31-21.44,13.97-3.57,2.33-7.15,4.66-10.72,6.99-.45.29-.89.58-1.34.87-.11.07-.22.14-.33.22-.05.03-.1.1-.15.02-.04-.05-.07-.11-.11-.16-.14-.22-.29-.44-.43-.66-.57-.88-1.14-1.76-1.7-2.65-1.13-1.78-2.26-3.56-3.37-5.35-9-14.49-17.19-29.34-24.63-44.69-14.99-30.89-26.42-62.49-34.68-95.82-.55-2.23-1.09-4.46-1.61-6.69-.02-.07-.03-.14-.05-.21-.02-.06.06-.02.09-.02.03,0,.06-.01.1-.02.13-.03.26-.06.38-.09.26-.06.51-.12.77-.18.51-.12,1.02-.24,1.53-.36,1.02-.24,2.04-.47,3.06-.71,4.08-.95,8.16-1.9,12.25-2.85,4.08-.95,8.17-1.9,12.25-2.85,2.04-.47,4.08-.95,6.12-1.42.51-.12,1.02-.24,1.53-.36.26-.06.51-.12.77-.18.13-.03.26-.06.38-.09.06-.01.13-.03.19-.04.1-.02.07.07.09.13.25,1.07.5,2.15.76,3.22,3.99,16.59,8.89,32.82,14.73,48.84,11.68,32.01,26.96,62.58,45.56,91.13Z"/>
  <path d="m74.74,448.43c7.48.91,14.97,1.82,22.45,2.72,3.74.45,7.48.91,11.22,1.36,1.87.23,3.74.45,5.61.68.23.03.47.06.7.08.06,0,.12.01.18.02.08,0,.06.07.06.12-.02.13-.03.26-.05.39-.06.52-.12,1.04-.18,1.56-.12,1.04-.24,2.08-.35,3.12-1.64,15.21-2.37,30.37-2.21,45.67.16,15.29,1.22,30.44,3.18,45.61.99,7.68,2.22,15.31,3.67,22.92.72,3.77,1.49,7.52,2.32,11.27.21.93.42,1.86.63,2.79l.16.7c.03.12.05.23.08.35.01.05-.05.02-.07.02-.03,0-.06.01-.08.02-.06.01-.11.03-.16.04-.44.1-.88.2-1.31.31-1.75.41-3.5.81-5.25,1.22-7.22,1.68-14.44,3.36-21.66,5.03-3.61.84-7.22,1.68-10.83,2.52-.06.01-.1.02-.12-.05-.02-.06-.03-.12-.04-.19-.03-.12-.06-.25-.09-.38-.06-.25-.12-.5-.17-.75-.11-.5-.23-1-.34-1.51-.23-1.01-.45-2.01-.67-3.02-.44-2.02-.86-4.04-1.27-6.07-3.03-14.99-5.25-30.03-6.67-45.26-2.86-30.65-2.44-60.75,1.27-91.31"/>
  <path d="m172.24,333.61c12.02,6.1,24.04,12.2,36.06,18.31.13.06.02.15-.02.23-.05.09-.09.19-.14.28-.1.19-.19.37-.28.56-.19.37-.38.75-.56,1.12-.37.75-.74,1.5-1.11,2.25-.74,1.51-1.46,3.02-2.18,4.54-1.43,3.05-2.81,6.11-4.15,9.2-2.69,6.21-5.17,12.49-7.47,18.85-4.59,12.72-8.36,25.59-11.34,38.78-1.53,6.76-2.84,13.55-3.94,20.39-.55,3.42-1.04,6.84-1.48,10.27-.05.43-.11.86-.16,1.29-.03.21-.05.43-.08.64,0,.05-.01.11-.02.16,0,.03,0,.05,0,.08,0,.02.02.07-.02.06-.09-.01-.19-.02-.28-.03-.75-.09-1.49-.18-2.24-.27-1.49-.18-2.99-.36-4.48-.54-6.51-.79-13.02-1.58-19.54-2.37-3.26-.4-6.51-.79-9.77-1.18-.81-.1-1.63-.2-2.44-.3-.41-.05-.81-.1-1.22-.15-.09-.01-.26.06-.24-.1.02-.12.03-.24.04-.36.03-.24.06-.48.09-.72.24-1.93.49-3.85.77-5.77,1.88-13.38,4.48-26.56,7.82-39.66,6.73-26.39,16.09-51.27,28.41-75.55Z"/>
  <path d="m165.86,142.75c5.8,6.2,11.6,12.4,17.4,18.6,2.9,3.1,5.8,6.2,8.7,9.3.36.39.72.78,1.09,1.16.09.1.18.19.27.29.05.05.28.19.17.29-.19.18-.39.37-.58.55-.78.73-1.55,1.47-2.33,2.2-1.55,1.48-3.09,2.97-4.62,4.47-12.19,11.95-23.6,24.49-34.35,37.74-10.75,13.25-20.66,27-29.84,41.38-4.61,7.23-9.01,14.59-13.2,22.07-2.11,3.77-4.16,7.56-6.16,11.39-.5.96-1,1.92-1.49,2.88-.25.48-.49.96-.74,1.45-.06.12-.12.24-.18.36-.05.09-.1-.01-.15-.04-.06-.03-.11-.06-.17-.08-.22-.11-.45-.23-.67-.34-1.78-.9-3.56-1.81-5.34-2.71-7.29-3.7-14.58-7.4-21.87-11.1-1.82-.93-3.64-1.85-5.47-2.78-.46-.23-.91-.46-1.37-.69-.23-.12-.46-.23-.68-.35-.11-.06-.23-.12-.34-.17-.03-.01-.06-.03-.09-.04-.05-.03.01-.06.03-.09.03-.06.07-.13.1-.19.52-1.03,1.05-2.06,1.58-3.08,2.12-4.09,4.29-8.15,6.52-12.19,8.26-14.92,17.24-29.31,27.02-43.29,19.67-28.15,41.7-53.54,66.78-76.99Z"/>
  <path d="m235.07,216.75c-5.18-5.53-10.35-11.07-15.53-16.6-2.59-2.77-5.18-5.53-7.76-8.3-1.29-1.38-2.59-2.77-3.88-4.15-.16-.17-.32-.35-.49-.52-.07-.07.05-.09.09-.13l.14-.13c.09-.09.18-.17.27-.26.37-.34.73-.68,1.1-1.02.73-.68,1.47-1.36,2.21-2.03,11.29-10.3,23.05-19.93,35.37-28.98,24.82-18.21,50.85-33.33,78.96-45.87,1.82-.81,3.65-1.61,5.49-2.4.23-.1.46-.2.69-.3.06-.02.12-.05.17-.07.05-.02.11-.11.15-.02.05.11.09.22.14.33.19.43.37.87.56,1.3.37.87.74,1.74,1.11,2.61,1.48,3.48,2.96,6.96,4.45,10.43,2.97,6.96,5.93,13.91,8.89,20.87.19.44.38.88.56,1.32.05.11.09.22.14.33.05.12-.2.15-.26.17-.22.1-.45.19-.67.29-.89.38-1.78.77-2.67,1.16-1.77.77-3.53,1.56-5.29,2.37-3.49,1.59-6.95,3.23-10.39,4.93-6.78,3.34-13.45,6.87-20.02,10.6-26.56,15.07-51.24,33.22-73.54,54.07Z"/>
  <path d="m371.76,199.14c-5.29-12.4-10.57-24.8-15.86-37.2-.06-.13.08-.13.16-.17.1-.04.19-.08.29-.12.19-.08.38-.16.57-.24.38-.16.77-.32,1.15-.48.77-.32,1.54-.64,2.3-.96,1.54-.63,3.09-1.26,4.64-1.87,3.12-1.23,6.26-2.42,9.42-3.57,6.39-2.32,12.83-4.46,19.34-6.43,26.07-7.87,52.21-12.71,79.36-14.7,1.91-.14,3.82-.26,5.73-.37.24-.01.48-.03.72-.04.12,0,.24-.01.36-.02.1,0,.26-.11.27.07.02.41.04.82.07,1.23.04.82.09,1.64.13,2.46.18,3.28.35,6.57.53,9.85.35,6.57.71,13.13,1.06,19.7.08,1.53.16,3.07.25,4.6.04.77.08,1.53.12,2.3,0,.06.08.25-.03.25-.11,0-.22.01-.33.02-.22.01-.43.02-.65.04-.43.02-.87.05-1.3.08-3.45.21-6.9.48-10.35.8-6.84.64-13.65,1.49-20.44,2.55-26.66,4.18-52.7,11.65-77.53,22.23Z"/>
</svg>
	
)



export default withRouter(Login)